#mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	color: #fff;
	padding: 30px 0;
	height: 100vh;
	#mobile-menu-main-links {
		margin-bottom: 30px;
	}
	#mobile-menu-container {
		text-align: center;
		position: relative;
		z-index: 20;
		top: 30%;
		left: 0;
		transform: translateY(-30%);
	}
}

#menu-close {
	font-size: 3rem;
	color: #fff;
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 25;
	padding: 10px;
}
