.form-control {
	height: 55px;
	border-radius: 6px;
	font-size: 1.4rem;
	font-weight: 600;
	&:focus {
		outline: none;
		box-shadow: none;
		border-color: darken(#6c757d, 10%);
	}
}

.form-error {
	padding-top: 5px;
	font-size: 1.3rem;
	font-style: italic;
}

#guide-input,
#email-input {
	&::-webkit-input-placeholder {
		/* Edge */
		font-style: italic;
		font-weight: 300;
		color: #979797;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		font-style: italic;
		font-weight: 300;
		color: #979797;
	}

	&::placeholder {
		font-style: italic;
		font-weight: 300;
		color: #979797;
	}
}
