@import "normal";

@font-face {
	font-family: "Kasting";
	src: url("./fonts/KastingScript.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

$i: 0;
@while $i <= 200 {
	.fs-#{$i} {
		font-size: 1px * $i;
	}
	.mt#{$i} {
		margin-top: 1px * $i;
	}
	.mb#{$i} {
		margin-bottom: 1px * $i;
	}
	.mb-xs-#{$i} {
		@media (max-width: 508px) {
			margin-bottom: 1px * $i;
		}
	}
	.mb-sm-#{$i} {
		@media (max-width: 767px) {
			margin-bottom: 1px * $i;
		}
	}
	.mb-md-down-#{$i} {
		@media (max-width: 992px) {
			margin-bottom: 1px * $i;
		}
	}
	.mb-md-#{$i} {
		@media (min-width: 769px) and (max-width: 992px) {
			margin-bottom: 1px * $i;
		}
	}
	.mb-lg-only-#{$i} {
		@media (min-width: 992px) and (max-width: 1199px) {
			margin-bottom: 1px * $i;
		}
	}
	.mb-lg-#{$i} {
		@media (min-width: 992px) {
			margin-bottom: 1px * $i;
		}
	}
	.pb-sm-#{$i} {
		@media (max-width: 768px) {
			padding-bottom: 1px * $i;
		}
	}
	.pb-md-#{$i} {
		@media (min-width: 769px) and (max-width: 992px) {
			padding-bottom: 1px * $i;
		}
	}
	.pb-lg-#{$i} {
		@media (min-width: 992px) {
			padding-bottom: 1px * $i;
		}
	}
	.mr#{$i} {
		margin-right: 1px * $i;
	}
	.ml#{$i} {
		margin-left: 1px * $i;
	}
	.pt#{$i} {
		padding-top: 1px * $i !important;
	}
	.pt-sm-#{$i} {
		@media (max-width: 768px) {
			padding-top: 1px * $i;
		}
	}
	.pt-md-#{$i} {
		@media (min-width: 769px) {
			padding-top: 1px * $i;
		}
	}
	.pt-lg-#{$i} {
		@media (min-width: 992px) {
			padding-top: 1px * $i;
		}
	}
	.pb#{$i} {
		padding-bottom: 1px * $i;
	}
	.ptb#{$i} {
		padding-top: 1px * $i !important;
		padding-bottom: 1px * $i !important;
	}
	.p#{$i} {
		padding: 1px * $i;
	}
	$i: $i + 5;
}

html,
body {
	font-size: 10px;
	font-family: "Open Sans", sans-serif;
}

p,
ul,
ol {
	font-size: 1.6rem;
}

a {
	white-space: pre-wrap; /* CSS3 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 0;
	margin-bottom: 20px;
	font-family: "PT Serif", serif;
	font-weight: 700;
}

h1,
.h1 {
	font-size: 3.2rem;
}
h2,
.h2 {
	font-size: 2.6rem;
}

h3,
.h3 {
	font-size: 2.2rem;
}

h4,
.h4 {
	font-size: 1.8rem;
}

h5,
.h5 {
	font-size: 1.6rem;
}
h6,
.h6 {
	font-size: 1.3rem;
}

.container-fluid {
	padding: 0;
}

.cursive {
	font-family: "Kasting";
}

@import "loading";

#app-container {
	padding-bottom: 90px;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
	&:after {
		mix-blend-mode: multiply;
		content: "";
		background-image: url("../images/seamless_paper_texture copy 7.png");
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		z-index: 0;
	}
	& > div {
		position: relative;
		z-index: 1;
	}
}

#nav-bar {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 20;
	width: 100%;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.2);
	}
	.container-fluid {
		position: relative;
		z-index: 10;
	}
	svg {
		color: #fff;
		font-size: 2.5rem;
	}
	.btns {
		padding: 0 15px;
		&:hover {
			cursor: pointer;
		}
	}
}

#app-container,
#nav-bar,
#mobile-menu-container {
	@media (min-width: 768px) {
		width: 767px;
		margin: 0 auto;
	}
}

#hero-img {
	border-bottom: 4px solid #fff;
	background-color: #fff;
}

#content-container {
	padding: 30px;
	margin-top: -60px;
	position: relative;
	z-index: 8;
	&.featured-image-full {
		margin-top: -80px;
	}
}

.content-container {
	padding: 30px;
	&.w-img {
		padding-top: 0;
		padding-bottom: 0;
		margin-bottom: 30px;
		img {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
		.content-box {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}

#content-box {
	background-color: #fff;
	padding: 30px 20px 20px;
	border-radius: 10px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.content-box {
	background-color: #fff;
	padding: 30px 20px 20px;
	border-radius: 10px 10px 0 10px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	&.brb-0 {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	&.brt-0 {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	&.coupon-box {
		padding: 20px;
	}
	.coupon {
		border: 2px dashed red;
		padding: 15px;
		text-align: center;
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6 {
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
		}
	}
}
#carousel-container {
	position: relative;
	.carousel-indicators-custom {
		position: absolute;
		bottom: 40px;
		left: 0;
		z-index: 10;
		width: 100%;
	}
}
#carousel-dir-container {
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 5;
	box-shadow: inset 50px 0px 25px -25px rgba(0, 0, 0, 0.45),
		inset -50px 0px 25px -25px rgba(0, 0, 0, 0.45);
	#carousel-dir {
		color: #fff;
		font-size: 2rem;
		position: relative;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		#carousel-dir-left {
			position: absolute;
			left: 10px;
		}
		#carousel-dir-right {
			position: absolute;
			right: 10px;
		}
	}
}
.carousel {
	border-bottom: 2px solid #fff;
}
.carousel-indicators-custom {
	padding: 20px 0;
	.col-auto {
		margin-right: 5px;
		&.last {
			margin-right: 0;
		}
	}
	svg {
		color: #fff;
	}
}
.single-stop {
	&:hover {
		cursor: pointer;
	}
}

.social-link {
	color: #fff;
	font-size: 2.6rem;
	&:hover {
		color: #fff;
	}
}

.has-small {
	line-height: 1;
}

// google translate
body {
	top: 0 !important;
}
#google-section {
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 10;
	#google-btn {
		font-size: 1.8rem;
		border-radius: 6px 0 0 6px;
		padding: 8px;
		color: #fff;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	}
	#google_translate_element {
		background-color: #fff;
		padding: 10px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

		div div {
			display: block !important;
			margin-left: 4px;
		}
	}
}
.goog-te-banner-frame.skiptranslate,
#goog-gt-tt {
	display: none !important;
	height: 0 !important;
	width: 0 !important;
}

a.link-list {
	display: block;
	padding: 15px 0;
	border-bottom: 1px solid #eee;
	font-size: 1.8rem;
	line-height: 1.2;
	color: #212529;
	&:last-child {
		border-bottom: 0;
	}
}
.embed-responsive-16by9.tour-stop::before {
	padding-top: 100%;
}
.embed-responsive.tour-stop .embed-responsive-item {
	height: 600px;
}

.email-field {
	order: 1;
}

.access-code {
	order: 2;
}

.submit-field {
	order: 3;
}

@import "btn";
@import "forms";
@import "cover";
@import "tip";
@import "menu";
