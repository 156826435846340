.btn-social {
	font-size: 2rem;
	color: #fff;
}
.btn-google {
	background-color: #ffa800;
}
.btn-trip {
	background-color: #56963c;
}
.btn-facebook {
	background-color: #3a589b;
}
.btn-yelp {
	background-color: #d52018;
}
.btn-venmo {
	background-color: #0396d5;
}
.btn-pay {
	background-color: #007cc6;
}
.btn-zelle {
	background-color: #760fdb;
}
.btn-paypal {
	background-color: #00318a;
}
.btn-cash {
	background-color: #00d54b;
}
.btn-redeem {
	background-color: #ad334e;
}
.btn-friend {
	color: #fff;
	padding: 10px;
	border-radius: 6px;
}

.btn-submit {
	background-color: #6c757d;
	color: #fff;
	border-radius: 6px;
	width: 100%;
	height: 42px;
	padding-right: 15px;
	padding-left: 15px;
	font-size: 1.4rem;
	border: 1px solid #6c757d;
	&:hover::before {
		content: "";
		position: relative;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: darken(#6c757d, 10%);
		border-radius: 0 6px 6px 0;
	}
	&:disabled:hover::before,
	&:disabled {
		background-color: #6c757d;
		border-color: #6c757d;
	}

	&:hover {
		background-color: darken(#6c757d, 10%);
		color: #fff;
	}
	&:focus {
		box-shadow: none;
		outline: none;
	}
	svg {
		position: relative;
		z-index: 10;
	}
}

.btn-link {
	display: block;
	width: 100%;
	color: #fff;
	outline: 0;
	&:hover {
		color: #fff;
		opacity: 0.5;
	}
	&:focus {
		box-shadow: none;
	}
}
#mobile-menu-main-links {
	.btn-link {
		padding: 5px;
		text-decoration: none;
		font-size: 2rem;
		line-height: 1.5;
		text-align: inherit;
		@media (max-width: 320px) {
			font-size: 1.8rem;
		}
	}
}

#mobile-menu-secondary-links {
	.btn-link {
		font-size: 1.4rem;
		margin-bottom: 10px;
		line-height: 1;
	}
}

.btn-none {
	display: inline-block;
}

.link-pointer {
	&:hover {
		cursor: pointer;
	}
	&.link-clicked {
		font-weight: bold;
	}
}
